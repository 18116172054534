<template>
  <div>
    <div class="mb-2 text-sm ">
      {{ $t.passwordMustContain }}:
    </div>
    <ul class="space-y-1">
      <li v-for="({ invalid, label }, key) in validationRules" :key>
        <vf-form-validation :invalid>
          {{ label }}
        </vf-form-validation>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  password: string
}>()

const { $t } = useNuxtApp()

const validationRules = computed(() => [
  { invalid: validatePasswordRequirement.length(props.password), label: $t.eightOrMoreCharacters },
  { invalid: validatePasswordRequirement.upper(props.password), label: $t.oneUppercaseLetter },
  { invalid: validatePasswordRequirement.lower(props.password), label: $t.oneLowercaseLetter },
  { invalid: validatePasswordRequirement.number(props.password), label: $t.oneNumber },
])
</script>
